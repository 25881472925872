import React, { Fragment } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import Layout from "layout"
import Container from "layout/Container"

import useProofOfPaymentIcon from "./hooks/useProofOfPaymentIcon"
import styles from "./utils/proofOfPayment.module.scss"

const ProofOfPaymentInvalid = (props) => {
  const { location } = props
  const { title, seoTitle } = props?.pageContext?.module
  const data = useProofOfPaymentIcon()
  return (
    <Layout
      title={title}
      seoTitle={seoTitle}
    >
      <Container isCentered>
        <div className={classNames("columns", styles["column"])}>
          <div className="column is-4-desktop is-12-mobile">
            <figure className={classNames("image mx-3")}>
              <Img
                fluid={data.email.childImageSharp.fluid}
                alt={`Proof of Payment Complete`}
                className={styles["image"]}
              />
            </figure>
          </div>
          <div className="column">
            <Fragment>
              <p className="mb-2">
                For inquiries, please contact us at pulsecare@medgrocer.com or visit our {" "}
                <Link to="/help-center" className="has-text-weight-bold">
                  Help Center
                </Link>
                .
              </p>
            </Fragment>
          </div>
        </div>
        <center>
          <Link to="/" className="mt-2 button is-primary is-medium">
            Finish
          </Link>
        </center>
      </Container>
    </Layout>
  )
}

export default ProofOfPaymentInvalid
